import React from "react"

class Footer extends React.Component {
    render() {
        return(
            <footer>Made with love by farhana</footer>
        )
    }    
}

export default Footer